import { getKevelTrackingData } from '@helpers/kevel'

import { IMPRESSION_GROUP_ID, IMPRESSION_GROUP_TYPE } from './constants'

export const getKevelFeaturedOffersEdges = (data) => {
  return data.accountsViewer.kevelFeaturedOffers.edges
}

export const getFeaturedOffers = (data) => {
  if (!data?.accountsViewer?.kevelFeaturedOffers) {
    return null
  }

  const edges = getKevelFeaturedOffersEdges(data)
    .filter((edge) => edge?.node?.offer)
    .map((kevelAdvert) => {
      return {
        node: {
          ...kevelAdvert.node.offer,
          kevelTracking: getKevelTrackingData(kevelAdvert.node)
        }
      }
    })

  return edges
}

export const getImpressionContent = (groupPosition) => ({
  groupType: IMPRESSION_GROUP_TYPE,
  groupId: IMPRESSION_GROUP_ID,
  groupPosition
})
