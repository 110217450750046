'use client'

import PropTypes from 'prop-types'

import OfferRail from '@components/offer_rail'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { useQuery } from '@hooks/use_query'

import {
  FEATURED_OFFERS_SUBTITLE_FALLBACK,
  FEATURED_OFFERS_TITLE_FALLBACK,
  FEATURED_OFFERS_QUERY,
  FEATURED_SUFFIX
} from './constants'
import { getFeaturedOffers, getImpressionContent } from './helpers'

const FeaturedOffers = ({ groupPosition }) => {
  const { t } = useI18n()
  const countryCode = useCountryCode()

  const { data, loading } = useQuery(FEATURED_OFFERS_QUERY, {
    variables: { countryCode }
  })

  return (
    <div data-testid='featured-discounts-rail'>
      <OfferRail
        hasMarginBottom
        impressionContent={getImpressionContent(groupPosition)}
        loading={loading}
        numberOfPlaceholders={10}
        offers={getFeaturedOffers(data)}
        pathSuffix={FEATURED_SUFFIX}
        subtitle={t('m_featured_subtitle_web', {
          fallback: FEATURED_OFFERS_SUBTITLE_FALLBACK
        })}
        title={t('d_featured_title', {
          fallback: FEATURED_OFFERS_TITLE_FALLBACK
        })}
      />
    </div>
  )
}

FeaturedOffers.propTypes = {
  groupPosition: PropTypes.number.isRequired
}

export default FeaturedOffers
