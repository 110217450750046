import { gql } from '@apollo/client'

import { OFFER_TILE_NEW_FRAGMENT } from '@components/offer_tile/constants'
import { KEVEL_TRACKING_FRAGMENT } from '@constants/kevel'

export const FEATURED_SUFFIX = '?source=featured_strip'
export const IMPRESSION_GROUP_TYPE = 'promotional_content'
export const IMPRESSION_GROUP_ID = 'featured_discounts'

export const FEATURED_OFFERS_TITLE_FALLBACK = 'Featured Discounts'
export const FEATURED_OFFERS_SUBTITLE_FALLBACK =
  "The discounts you won't want to miss"

export const FEATURED_OFFERS_QUERY = gql`
  query FeaturedOffersQuery($countryCode: String!) {
    accountsViewer {
      id
      kevelFeaturedOffers: kevelAdverts(
        countrySlug: $countryCode
        platform: WEB
        placementType: FEATURED
        count: 10
      ) {
        edges {
          node {
            ... on KevelOffer {
              ...KevelTrackingViewer
              offer {
                ...OfferTileNewOffer
              }
            }
          }
        }
      }
    }
  }
  ${OFFER_TILE_NEW_FRAGMENT}
  ${KEVEL_TRACKING_FRAGMENT}
`
